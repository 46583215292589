const dbColors = {
  highlight: "#1a3c8c",
  background: "#ffffff",
  white: "#ffffff",
  black: "#000000",
  altTextColour: "#000000"
};

const green2purpleGradient = [
  "#32C777",  //gree-blue
  "#2B8026",  //dark green
  "#F23838",  //coral red
  "#801B54",  //dark purple
  "#C7619B"  //light purple
]

const green3purpleGradientRGB = [
  [50, 199, 119],  //green-blue
  [43, 128, 38],  //dark green
  [242, 56, 56],  //coral red
  [128, 27, 84],  //darker purple
  [199, 97, 155]  //lighter purple
]

const blue2orangeGradient = [
  "#0D212C",  //dark gray navy
  "#346070",  //dark gray blue
  "#226EAD",  //dark blue
  "#5DC0C7",  //light blue
  "#FFC432",  //yellow
  "#EA8727",  //orange
  "#AE5E2D"  //dark orange
]

const blue2orangeGradientRGB = [
  [13, 33, 44],
  [52, 96, 112],
  [34, 110, 173],
  [93, 192, 199],
  [255, 196, 50],
  [234, 135, 39],
  [174, 54, 45]
]

const themeDarkBlue = blue2orangeGradient[0]

export {
  dbColors,
  green2purpleGradient,
  green3purpleGradientRGB,
  blue2orangeGradient,
  themeDarkBlue
}

/*All data for the dashboard UI panel is stored here
and exported as a single object to be distributed to the
panel components in app.js*/
