import React from "react";
import styled from "styled-components";
import ReportLayerGroupHeaderPanel from "./report-layer-group-header-panel";
import ReportRideSurveylayerTogglesContainer from "./report-ride-survey-layer-toggles-container";
import ReportInfraRequestlayerTogglesContainer from "./report-infra-request-layer-toggles-container";
import { uiInfo } from "../constants/reportDashboardUiInfo";

const Wrapper = styled.div`
  top: 6%;
  height: fit-content;
  padding-top: 0em;
  align: centre;
  position: absolute;
  float: left;
  clear: none;
  z-index: 2;
  overflow: hidden
`;

const DashboardLayerUiContainer = () => {
  return (
    <Wrapper className={"dashboardLayerUi_container"}>
      <ReportLayerGroupHeaderPanel info={uiInfo.rideSurveyGroupinfo}/>
      <ReportRideSurveylayerTogglesContainer/>
      <ReportLayerGroupHeaderPanel info={uiInfo.infraRequestGroupInfo}/>
      <ReportInfraRequestlayerTogglesContainer/>
    </Wrapper>
  )
};

export default DashboardLayerUiContainer;
