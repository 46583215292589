import React from "react";

const PanelCrossIcon = ({
  style = {
    float: "right",
    clear: "none",
    margin: "15px 0px 0px 0px"
  },
  fill = "#ffffff",
  width = "30px",
  className = "",
  viewBox = "0 0 250 250",
  transform = ""
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    transform={transform}
  >
  <polygon fill={fill} points="63.4,50 99.6,13.8 99.6,13.8 99.7,13.7 86.3,0.3 50,36.6 13.7,0.3 0.3,13.7 0.3,13.7 0.1,13.8 36.5,50.1 0.3,86.3
    0.3,86.3 0.1,86.4 13.6,99.9 49.9,63.6 86.2,99.9 99.6,86.4 99.6,86.4 99.7,86.3"/>
  </svg>
);

export default PanelCrossIcon;
