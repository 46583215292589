import React from "react";
import { component } from "react";
import { connect } from "react-redux";
import ReportLayerToggle from "./report-layer-toggle";
import { uiInfo } from "../constants/reportDashboardUiInfo";

class ReportInfraRequestlayerTogglesContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  //insert any functions for the class and for the component

  render() {
    return (
      //JSX code for component
      <div
        className={"infraRequestLayersContainer"}
        id={"infraRequestLayersContainer"}
        style={{
          paddingLeft: "2.6em",
          display: this.props.dashboard.dbUiState.layerGroupPanels.infraRequestGroup.active?"block":"none"
        }}
      >
        <ReportLayerToggle info={uiInfo.widthPanelInfo}/>
        <ReportLayerToggle info={uiInfo.separationPanelInfo}/>
        <ReportLayerToggle info={uiInfo.parkingPanelInfo}/>
        <ReportLayerToggle info={uiInfo.timingPanelInfo}/>
        <ReportLayerToggle info={uiInfo.calmingPanelInfo}/>
        <ReportLayerToggle info={uiInfo.closurePanelInfo}/>
        <ReportLayerToggle info={uiInfo.irotherPanelInfo}/>
      </div>
    );
  }
}

ReportInfraRequestlayerTogglesContainer.defaultProps = {
};

const mapStateToProps = state => {
  return state;
};
const dispatchToProps = dispatch => ({ dispatch });

export default connect(
  mapStateToProps,
  {}
)(ReportInfraRequestlayerTogglesContainer);
