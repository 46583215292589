import React from "react";
import { component } from "react";
import { connect } from "react-redux";
import { layerConfigChange } from "kepler.gl/actions";
import { panelExpandToggle } from "../dashboard-reducer";
import { dbColors } from "../constants/dbColors.js";

import PanelCrossIcon from "../icon/panel-cross";
import PanelArrowIcon from "../icon/panel-arrow";

//COLOR DEFINITIONS
const background_LayerOff = dbColors.white;
const fill_LayerOff = dbColors.black;
const background_LayerOn = dbColors.black;
const fill_LayerOn = dbColors.white;

class ReportLayerToggle extends React.Component {
  constructor(props) {
    super(props);
  }

  //insert any functions for the class and for the component
  layerSwitch = () => {
    if (
      this.props.info.title === "Dwell Time" &&
      this.props.keplerGl.map1.mapState.dragRotate === false &&
      this.props.keplerGl.map1.visState.layers[this.props.info.layerToControl]
        .config.isVisible === false
    ) {
      this.props.togglePerspective();
    }

    let isVisible = !this.props.keplerGl.map1.visState.layers[
      this.props.info.layerToControl
    ].config.isVisible;
    this.props.layerConfigChange(
      this.props.keplerGl.map1.visState.layers[this.props.info.layerToControl],
      { isVisible }
    );
  };

  bgHelper = () => {
    if (this.props.keplerGl.map1 !== undefined) {
      if (
        this.props.keplerGl.map1.visState.layers[
          this.props.info.layerToControl
        ] !== undefined &&
        this.props.keplerGl.map1.visState.layers[this.props.info.layerToControl]
          .config.isVisible === true
      ) {
        return this.props.info.colour;
      } else {
        return background_LayerOff;
      }
    }
    return;
  };

  fillHelper = () => {
    if (this.props.keplerGl.map1 !== undefined) {
      if (
        this.props.keplerGl.map1.visState.layers[
          this.props.info.layerToControl
        ] !== undefined &&
        this.props.keplerGl.map1.visState.layers[this.props.info.layerToControl]
          .config.isVisible === true
      ) {
        return fill_LayerOn;
      } else {
        return this.props.info.colour;
      }
    }
    return;
  };

  //{this.iconFillHelper(this.props.info.title, this.fillHelper())}
  render() {
    return (
      //JSX code for component
      <div
        className={"reportLayerToggle"}
        id={this.props.info.panelId}
        style={{
          width: "14em",
          height: this.props.dashboard.dbUiState.layerPanels[
            this.props.info.panelId
          ].active
            ? "fit-content"
            : "2em",
          transition: "height 0.1s",
          borderRadius: "7px",
          margin: "0.4em 0em 0em 0em",
          backgroundColor: this.bgHelper(),
          color: this.fillHelper(),
          borderStyle: "none",
          borderWidth: "1px",
          fontFamily: "Roboto, sans-serif",
          opacity: 0.9,
          cursor: "pointer",
          overflow: "hidden",
          display: "block"
        }}
      >
        <div
          onClick={() => this.layerSwitch()}
          className={"reportLayerToggle_contentContainer"}
          style={{
            width: "80%",
            height: "100%",
            float: "left",
            clear: "none"
          }}
        >
          <p
            className={"reportLayerToggle_title"}
            style={{
              padding: "6px 10px",
              fontSize: "1em",
              margin: 0,
              fontWeight: 300,
              float: "left",
              clear: "none"
            }}
          >
            {this.props.info.title}
          </p>
          <p
            className={"reportLayerToggle_description"}
            style={{
              width: "100%",
              margin: "0px",
              fontSize: "small",
              fontWeight: "300",
              float: "left",
              clear: "none",
              padding: "0px 10px 10px 10px"
            }}
          >
            {this.props.info.layerDescription}
          </p>
        </div>
        <div
          onClick={() => this.props.panelExpandToggle(this.props.info.panelId)}
          className={"reportLayerToggle_disclosureIconContainer"}
          style={{
            width: "20%",
            left: "80%",
            float: "left",
            clear: "none",
            height: "fit-content"
          }}
        >
          {this.props.dashboard.dbUiState.layerPanels[this.props.info.panelId]
            .active ? (
            <PanelCrossIcon fill={this.fillHelper()} style={{
              float: "right",
              clear: "none",
              margin: "14px 0px 0px 0px"}}
            />
          ) : (
            <PanelArrowIcon fill={this.fillHelper()} style={{
              float: "right",
              clear: "none",
              margin: "10px 0px 0px 0px"}}
            />
          )}
        </div>
      </div>
    );
  }
}

ReportLayerToggle.defaultProps = {
  info: {
    title: "Layer Title",
    layerToControl: null,
    panelId: "panelDefault",
    layerDescription: "Layer description",
    infoUrl: "https://seesense.cc/pages/more-information-see-sense-data-insights-dashboard",
    colour: dbColors.white
  }
};

const mapStateToProps = state => {
  return state;
};
const dispatchToProps = dispatch => ({ dispatch });

export default connect(
  mapStateToProps,
  { layerConfigChange, panelExpandToggle }
)(ReportLayerToggle);
