import React from "react";

const PanelArrowIcon = ({
  style = {
    float: "right",
    clear: "none",
    margin: "17px 0px 0px 0px"
  },
  fill = "#ffffff",
  width = "30px",
  className = "",
  viewBox = "0 0 250 250",
  transform=""
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    transform={transform}
  >
  <polygon fill={fill} points="99.7,31.9 50,81.6 0.3,31.9 13.7,18.4 50,54.7 86.3,18.4"/>
  </svg>
);

export default PanelArrowIcon;
