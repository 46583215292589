export const dbUiInfoSustrans = {
  bikesPanelInfo: {
    title: "Bikes",
    layerToControl: 0,
    panelId: "panelBikes",
    layerDescription: "Sustrans e-cargo bike locations, hover over a bike location to see its exact location and stats.",
    infoUrl: "https://seesense.cc/pages/more-information-see-sense-data-insights-dashboard"
  },
  routePopularityPanelInfo: {
    title: "Route Popularity",
    layerToControl: 1,
    panelId: "panelRP",
    layerDescription: "Heatmap showing popular cycle routes, lighter coloured areas indicate where most cyclists are.",
    infoUrl: "https://seesense.cc/pages/more-information-see-sense-data-insights-dashboard#route_popularity_info"
  },
  speedPanelInfo: {
    title: "Speed",
    layerToControl: 2,
    panelId: "panelSpeed",
    layerDescription: "Heatmap showing the average speed of all cyclist passing through that area with green being fast and red slow.",
    infoUrl: "https://seesense.cc/pages/more-information-see-sense-data-insights-dashboard#speed_info"
  },
  brakingPanelInfo: {
    title: "Braking",
    layerToControl: 3,
    panelId: "panelBraking",
    layerDescription: "Heatmap showing where cyclists have had to brake suddenly or sharply, where a darker colour indicates heavier braking.",
    infoUrl: "https://seesense.cc/pages/more-information-see-sense-data-insights-dashboard#braking_info"
  },
  swervingPanelInfo: {
    title: "Swerving",
    layerToControl: 4,
    panelId: "panelSwerving",
    layerDescription: "Heatmap showing where cyclists have had to swerve where a darker colour indicates more swerving.",
    infoUrl: "https://seesense.cc/pages/more-information-see-sense-data-insights-dashboard#swerving_info"
  }
};

export const sustransInsightUiInfo = {
  bikes: {
    title: "Active Bikes",
    number: 23,
    unit: null,
    insightId: "bikesInsight",
    InsightDescription: "Total number of current active bikes with tracking devices fitted."
  },
  distance: {
    title: "Total Kilometres",
    number: 345,
    unit: "km",
    insightId: "distanceInsight",
    InsightDescription: "Total distance covered by all bikes with tracking devices fitted."
  },
  speed: {
    title: "Avg. Speed",
    number: 20,
    unit: "kmh",
    insightId: "avespeedInsight",
    InsightDescription: "Average traveling speed for all bikes with tracking devices fitted."
  },
  co2: {
    title: "CO2 Saved",
    number: 50,
    unit: "kg",
    insightId: "co2Insight",
    InsightDescription: "Total CO2 emissions saved by using e-cargo bikes instead of driving."
  }
};

/*All data for the dashboard UI panel is stored here
and exported as a single object to be distributed to the
panel components in app.js*/
