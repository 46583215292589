import React from "react";
import { component } from "react";
import { connect } from "react-redux";
import ReportLayerToggle from "./report-layer-toggle";
import { uiInfo } from "../constants/reportDashboardUiInfo";

class ReportRideSurveylayerTogglesContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  //insert any functions for the class and for the component

  render() {
    return (
      //JSX code for component
      <div
        className={"rideSurveyLayersContainer"}
        id={"rideSurveyLayersContainer"}
        style={{
          paddingLeft: "2.6em",
          display: this.props.dashboard.dbUiState.layerGroupPanels.rideSurveyGroup.active?"block":"none"
        }}
      >
        <ReportLayerToggle info={uiInfo.closePassPanelInfo}/>
        <ReportLayerToggle info={uiInfo.collisonPanelInfo}/>
        <ReportLayerToggle info={uiInfo.potholePanelInfo}/>
        <ReportLayerToggle info={uiInfo.obstructionPanelInfo}/>
        <ReportLayerToggle info={uiInfo.rsotherPanelInfo}/>
      </div>
    );
  }
}

ReportRideSurveylayerTogglesContainer.defaultProps = {
};

const mapStateToProps = state => {
  return state;
};
const dispatchToProps = dispatch => ({ dispatch });

export default connect(
  mapStateToProps,
  {}
)(ReportRideSurveylayerTogglesContainer);
