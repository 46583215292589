import React from "react";
import { component } from "react";
import { connect } from "react-redux";
import { groupExpandToggle } from "../dashboard-reducer";
import { dbColors } from "../constants/dbColors.js";

import PanelCrossIcon from "../icon/panel-cross";
import PanelArrowIcon from "../icon/panel-arrow";

class ReportLayerGroupHeaderPanel extends React.Component {
  constructor(props) {
    super(props);
  }

  //insert any functions for the class and for the component

  render() {
    return (
      //JSX code for component
      <div
        className={"mainLayerPanel"}
        style={{
          width: "16em",
          height: "2.5em",
          transition: "height 0.1s",
          borderRadius: "7px",
          margin: "0.6em 0em 0em 0.6em",
          backgroundColor: dbColors.black,
          color: dbColors.white,
          borderStyle: "none",
          borderWidth: "1px",
          fontFamily: "Roboto, sans-serif",
          cursor: "pointer",
          overflow: "hidden",
          display: "block"
        }}
      >
        <div
          className={"layerPanelHeaderContentContainer"}
          style={{
            width: "80%",
            height: "100%",
            float: "left",
            clear: "none"
          }}
        >
          <p
            className={"layerPanelHeaderTitle"}
            style={{
              padding: "8px 10px",
              fontSize: "large",
              margin: 0,
              fontWeight: 400,
              float: "left",
              clear: "none"
            }}
          >
            {this.props.info.title}
          </p>
        </div>
        <div
          onClick={() => this.props.groupExpandToggle(this.props.info.panelId)}
          className={"layerPanelExpandToggleContainer"}
          style={{
            width: "20%",
            left: "80%",
            float: "left",
            clear: "none",
            height: "fit-content"
          }}
        >
          {this.props.dashboard.dbUiState.layerGroupPanels[this.props.info.panelId].active ? (
            <PanelCrossIcon fill={dbColors.white} style={{
              float: "right",
              clear: "none",
              margin: "14px 0px 0px 0px"}}
            />
          ) : (
            <PanelArrowIcon fill={dbColors.white} style={{
              float: "right",
              clear: "none",
              margin: "14px 0px 0px 0px"}}
            />
          )}
        </div>
      </div>
    );
  }
}

ReportLayerGroupHeaderPanel.defaultProps = {
  info: {
  }
};

const mapStateToProps = state => {
  return state;
};
const dispatchToProps = dispatch => ({ dispatch });

export default connect(
  mapStateToProps,
  { groupExpandToggle }
)(ReportLayerGroupHeaderPanel);
