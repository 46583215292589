import { green2purpleGradient, blue2orangeGradient } from "./dbColors";

export const uiInfo = {
  rideSurveyGroupinfo: {
    title: "Ride Survey",
    panelId: "rideSurveyGroup"
  },
  infraRequestGroupInfo: {
    title: "Infrastructure Request",
    panelId: "infraRequestGroup"
  },
  closePassPanelInfo: {
    title: "Close Pass",
    layerToControl: 0,
    panelId: "closePassPanel",
    layerDescription: "Motorists passed too closely when overtaking.",
    infoUrl: "https://seesense.cc/pages/more-information-see-sense-data-insights-dashboard",
    colour: green2purpleGradient[0],
    useAltTextColour: false
  },
  collisonPanelInfo: {
    title: "Collision",
    layerToControl: 1,
    panelId: "collisionPanel",
    layerDescription: "A collision occurred between a cyclist and someone/something else.",
    infoUrl: "https://seesense.cc/pages/more-information-see-sense-data-insights-dashboard",
    colour: green2purpleGradient[1],
    useAltTextColour: false
  },
  potholePanelInfo: {
    title: "Pothole",
    layerToControl: 2,
    panelId: "potholePanel",
    layerDescription: "Pothole or road defect presented a safety issue.",
    infoUrl: "https://seesense.cc/pages/more-information-see-sense-data-insights-dashboard",
    colour: green2purpleGradient[2],
    useAltTextColour: false
  },
  obstructionPanelInfo: {
    title: "Obstruction",
    layerToControl: 3,
    panelId: "obstructionPanel",
    layerDescription: "The convenient use of way was prevented eg. parked car in bike lane.",
    infoUrl: "https://seesense.cc/pages/more-information-see-sense-data-insights-dashboard",
    colour: green2purpleGradient[3],
    useAltTextColour: false
  },
  rsotherPanelInfo: {
    title: "Other",
    layerToControl: 4,
    panelId: "rsotherPanel",
    layerDescription: "Other option, see comment from cyclist.",
    infoUrl: "https://seesense.cc/pages/more-information-see-sense-data-insights-dashboard",
    colour: green2purpleGradient[4],
    useAltTextColour: false
  },
  widthPanelInfo: {
    title: "Width",
    layerToControl: 5,
    panelId: "widthPanel",
    layerDescription: "Add more width to improve cycle flow.",
    infoUrl: "https://seesense.cc/pages/more-information-see-sense-data-insights-dashboard",
    colour: blue2orangeGradient[0],
    useAltTextColour: false
  },
  separationPanelInfo: {
    title: "Separation",
    layerToControl: 6,
    panelId: "separationPanel",
    layerDescription: "Add infrastructure to separate cycling and motor traffic.",
    infoUrl: "https://seesense.cc/pages/more-information-see-sense-data-insights-dashboard",
    colour: blue2orangeGradient[1],
    useAltTextColour: false
  },
  parkingPanelInfo: {
    title: "Parking",
    layerToControl: 7,
    panelId: "parkingPanel",
    layerDescription: "Add cycle parking.",
    infoUrl: "https://seesense.cc/pages/more-information-see-sense-data-insights-dashboard",
    colour: blue2orangeGradient[2],
    useAltTextColour: false
  },
  timingPanelInfo: {
    title: "Timing",
    layerToControl: 8,
    panelId: "timingPanel",
    layerDescription: "Improve traffic signal timing to reduce delays.",
    infoUrl: "https://seesense.cc/pages/more-information-see-sense-data-insights-dashboard",
    colour: blue2orangeGradient[3],
    useAltTextColour: false
  },
  calmingPanelInfo: {
    title: "Calming",
    layerToControl: 9,
    panelId: "calmingPanel",
    layerDescription: "Add measures to reduce motor traffic speed.",
    infoUrl: "https://seesense.cc/pages/more-information-see-sense-data-insights-dashboard",
    colour: blue2orangeGradient[4],
    useAltTextColour: false
  },
  closurePanelInfo: {
    title: "Closures",
    layerToControl: 10,
    panelId: "closuresPanel",
    layerDescription: "Close to through traffic to stop rat-running.",
    infoUrl: "https://seesense.cc/pages/more-information-see-sense-data-insights-dashboard",
    colour: blue2orangeGradient[5],
    useAltTextColour: false
  },
  irotherPanelInfo: {
    title: "Other",
    layerToControl: 11,
    panelId: "irotherPanel",
    layerDescription: "Other option, see comment from cyclist.",
    infoUrl: "https://seesense.cc/pages/more-information-see-sense-data-insights-dashboard",
    colour: blue2orangeGradient[6],
    useAltTextColour: false
  }
};

/*All data for the dashboard UI panel is stored here
and exported as a single object to be distributed to the
panel components in app.js*/
